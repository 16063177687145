import axios from "axios";
import {
  BASE_URL
} from "./config";
import {
  createApp
} from "vue";
import App from "./App.vue";
import "ant-design-vue/dist/antd.css";
import router from "./plugins/router";
import {
  Overlay
} from "vant";
import {
  Loading
} from "vant";
import {
  Notify
} from "vant";
import {
  Dialog
} from "vant";
import 'vant/lib/index.css';
import {
  List
} from "vant";
import {
  Cell
} from "vant";
import { Popup } from 'vant';

var app = createApp(App);
axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    var token = localStorage.getItem("token");
    var jwtToken = localStorage.getItem("jwtToken");
    config.headers["Auth-Way"] = "new";
    config.headers["Token"] = token;
    config.headers["JWT_Token"] = jwtToken;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data) {
      if (response.data.code != 0) {
        return Promise.reject(response);
      }
    }
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("jwtToken");
      if (
        window.location.hash.startsWith("#/login") ||
        window.location.hash.startsWith("#login") ||
        window.location.hash.startsWith("login")
      ) {
        return Promise.reject(error);
      }
      if (error.request != null) {
        if (error.request.responseURL != null) {
          if (error.request.responseURL.indexOf('/api/checkLogin') != -1) {
            return Promise.reject(error);
          }
        }
      }


      var type = window.location.hash.substring(1);
      window.location.hash = "#login?type=" + type;
    }
    return Promise.reject(error);
  }
);

app
  .use(router)
  .use(Overlay)
  .use(Loading)
  .use(Notify)
  .use(Dialog)
  .use(List)
  .use(Cell)
  .use(Popup)
  .mount("#app");
