<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
   box-sizing: border-box;
}
#app {
 height: 100%;
}
</style>
