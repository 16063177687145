import routerSurface from "../router/index";
import * as VueRouter from "vue-router";
// Console.log(routerSurface,"路由表")
const router = VueRouter.createRouter({
  routes: routerSurface,
  // 不要改，改成createWebHistory会导致wx.config有bug https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html
  history: VueRouter.createWebHashHistory("http://localhost:8889/"),
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
