export default [
  {
    path: "/testJoin",
    name: "testJoin",
    component: () => import("../views/coupon/testJoin"),
    meta: {
      title: "胜寒入会考试专用券",
    },
  },
  {
    path: "/testOrdinary",
    name: "testOrdinary",
    component: () => import("../views/coupon/testOrdinary"),
    meta: {
      title: "NF智商测试专用券",
    },
  },
  {
    path: "/buySuccess",
    name: "buySuccess",
    component: () => import("../views/coupon/buySuccess"),
    meta: {
      title: "购买成功",
    },
  },
];
