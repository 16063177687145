// 路由表
import coupon from "./coupon";
import login from "./login";

export default [
  {
    path: "/",
    component: () => import("../views/login/main"),
    meta: {
      title: "主页",
    },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () => import("../views/wallet/wallet"),
    meta: {
      title: "能否·钱包",
    },
  },
  {
    path: "/wallet1",
    name: "wallet1",
    component: () => import("../views/wallet/wallet1"),
    meta: {
      title: "能否·钱包1",
    },
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/community/detail.vue'),
    meta: {
      title: '能否·社群'
    }
  },
  {
    path: '/payRecord',
    name: 'payRecord',
    component: () => import('../views/payRecord/index.vue'),
    meta: {
      title: '支付记录'
    }
  },
  {
    path: "/result",
    name: "result",
    component: () => import("../views/result/result"),
    meta: {
      title: "成绩报告",
    },
  },
  {
    path: "/wallet/rechangeRecord",
    name: "rechangeRecord",
    component: () => import("../views/wallet/rechangeRecord"),
    meta: {
      title: "充值记录",
    },
  },
  ...login,
  ...coupon,
];
